<template>
  <v-dialog
    v-model="dialog"
    persistent
    scrollable
    width="1200"
  >
    <v-card min-height="400">
      <v-card-title class="caption">
        <v-row>
          <v-col class="d-flex justify-end">
            <v-toolbar-items>
              <v-btn
                icon
                color="black"
                dark
                @click="dialog = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="mt-4">
        <v-row justify="center">
          <v-col cols="12" sm="4" v-for="(img, index) in imageUrl" :key="index">
            <viewer-image
              :key="index"
              :src="img"
              height="300"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  data() {
    return {
      dialog: false,
      imageUrl: [],
    };
  },
  watch: {
    dialog(newVal) {
    },
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-image {
    cursor: pointer;
    transition: .3s ease;
  }
  .v-image:hover {
    transform: scale(1.2, 1.2);
  }
}
</style>
