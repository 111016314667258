var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-row',[_c('v-col',[_c('h4',{staticClass:"pl-2"},[_vm._v(_vm._s(_vm.$_strings.order.TRIP_INFO))])])],1),_c('v-row',[_c('v-col',{staticClass:"pa-1"},[_c('v-data-table',{staticClass:"grey lighten-4",attrs:{"headers":_vm.headersMultipickInfo,"items":_vm.multiPickItems,"calculate-widths":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.multiPick",fn:function(ref){
var item = ref.item;
return _vm._l((item.multiPick),function(itemMultiPick,index){return _c('p',{key:index,staticClass:"ma-0"},[_vm._v(" "+_vm._s(index + 1)+" - "+_vm._s(itemMultiPick.locationName)+" ")])})}},{key:"item.originLocationName",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.originLocationName))])]}}],null,true)})],1),_c('v-col',{staticClass:"pa-1"},[_c('v-data-table',{staticClass:"grey lighten-4",attrs:{"headers":_vm.headersMultidropInfo,"items":_vm.multiDropItems,"calculate-widths":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.multiDrop",fn:function(ref){
var item = ref.item;
return _vm._l((item.multiDrop),function(itemMultiDrop,index){return _c('p',{key:index,staticClass:"ma-0"},[_vm._v(" "+_vm._s(index + 1)+" - "+_vm._s(itemMultiDrop.locationName)+" ")])})}},{key:"item.destinationLocationName",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.destinationLocationName))])]}},{key:"item.estimateArrival",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"ma-0"},[_vm._v(_vm._s(_vm.dateFormat(item.estimateArrival)))]),(item.estimateArrival)?_c('p',[_vm._v(_vm._s(_vm.timeFormat(item.estimateArrival)))]):_vm._e()]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }