<template>
  <v-container class="mb-5" fluid>
    <v-row>
      <v-col>
        <v-card :class="isMobile ? 'pa-0 ma-0': 'pl-2 pr-2'" elevation="0" tile>
          <v-tabs
            v-model="activeTab"
            background-color="transparent"
          >
            <v-tab
              v-for="tab in tabs"
              :key="tab"
              @click="changePath(tab)"
              class="font-weight-bold text-capitalize"
            >
              {{getTabTitle(tab)}}
            </v-tab>
          </v-tabs>
          <v-divider />
        </v-card>
      </v-col>
    </v-row>
    <v-tabs-items v-model="activeTab">
      <v-tab-item v-for="tab in tabs" :key="tab">
        <MainList
          :status="tab"
          :key="key[tab]"
          :itemsFilter="itemsFilter"
          :pageFilters="pageFilters"
          @fetching="fetching"
        />
      </v-tab-item>
    </v-tabs-items>
    <FooterOrder :tab="activeTab"/>
  </v-container>
</template>

<script>

import MainList from './List/MainList.vue';
import FooterOrder from './FooterOrder.vue';

export default {
  name: 'index',
  components: {
    MainList,
    FooterOrder,
  },
  created() {
    const { status } = this.$route.params;
    this.setActiveTab(status);
  },
  watch: {
    currentPath() {
      const { status } = this.$route.params;
      this.setActiveTab(status);
    },
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xs;
    },
    currentPath() {
      return this.$route.path;
    },
  },
  data() {
    return {
      path: '',
      activeTab: 0,
      itemsFilter: {
        itemsLocation: [],
        itemsCustomers: [],
      },
      pageFilters: {
        location: {
          search: '',
          page: 0,
          size: 25,
          totalData: 0,
        },
        shipper: {
          search: '',
          page: 0,
          size: 25,
          totalData: 0,
        },
      },
      tabs: ['waiting', 'process', 'finish'],
      key: {
        waiting: 0,
        process: 0,
        finish: 0,
      },
    };
  },
  methods: {
    getTabTitle(tab) {
      const title = {
        waiting: this.$_strings.order.WAITING,
        process: this.$_strings.order.PROCESS,
        finish: this.$_strings.order.COMPLETED,
      };
      return title[tab];
    },
    fetching(tab) {
      this.key[tab] += 1;
    },
    changePath(path) {
      if (this.path.localeCompare(path) !== 0) {
        this.path = path;
        this.$router.replace(`/main/pesanan/list/${path}`);
      }
    },
    setActiveTab(status) {
      this.path = status;
      this.activeTab = this.tabs.indexOf(status);
    },
  },
};
</script>
<style scoped lang="scss">
  ::v-deep .v-data-table-header {
    tr {
      .amber.accent-3 {
        background-color: #f1c231 !important;
      }
    }
  }
</style>
