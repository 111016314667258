<template>
  <section>
    <v-row>
      <v-col><h4 class="pl-2">{{$_strings.order.TRIP_INFO}}</h4></v-col>
    </v-row>
    <v-row>
      <v-col class="pa-1">
        <v-data-table
          :headers="headersMultipickInfo"
          :items="multiPickItems"
          calculate-widths
          class="grey lighten-4"
          hide-default-footer
        >
          <template v-slot:[`item.multiPick`]="{item}" >
            <p class="ma-0" v-for="(itemMultiPick, index) in item.multiPick" :key="index">
              {{index + 1}} - {{itemMultiPick.locationName}}
            </p>
          </template>
          <template v-slot:[`item.originLocationName`]="{item}" >
            <span>{{item.originLocationName}}</span>
          </template>
        </v-data-table>
      </v-col>
      <v-col class="pa-1">
        <v-data-table
          :headers="headersMultidropInfo"
          :items="multiDropItems"
          calculate-widths
          class="grey lighten-4"
          hide-default-footer
        >
          <template v-slot:[`item.multiDrop`]="{item}" >
            <p class="ma-0" v-for="(itemMultiDrop, index) in item.multiDrop" :key="index">
              {{index + 1}} - {{itemMultiDrop.locationName}}
            </p>
          </template>
          <template v-slot:[`item.destinationLocationName`]="{item}" >
            <span>{{item.destinationLocationName}}</span>
          </template>
          <template v-slot:[`item.estimateArrival`]={item}>
            <p class="ma-0">{{dateFormat(item.estimateArrival)}}</p>
            <p v-if="item.estimateArrival">{{timeFormat(item.estimateArrival)}}</p>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import { dateFormat, timeFormat } from '@/helper/commonHelpers';

export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      headersMultipickInfo: [
        {
          text: this.$_strings.order.ORIGIN,
          value: 'originLocationName',
          class: 'black--text amber accent-3 text-capitalize',
          sortable: false,
        },
        {
          text: this.$_strings.order.MULTIPICK,
          value: 'multiPick',
          class: 'black--text amber accent-3 text-capitalize',
          sortable: false,
        },
      ],
      headersMultidropInfo: [
        {
          text: this.$_strings.order.MULTIDROP,
          value: 'multiDrop',
          class: 'black--text amber accent-3 text-capitalize',
          sortable: false,
        },
        {
          text: this.$_strings.order.DESTINATION,
          value: 'destinationLocationName',
          class: 'black--text amber accent-3 text-capitalize',
          sortable: false,
        },
        {
          text: this.$_strings.order.ETA,
          value: 'estimateArrival',
          width: '200px',
          class: 'black--text amber accent-3 text-uppercase',
          sortable: false,
        },
      ],
    };
  },
  computed: {
    multiPickItems() {
      const { originLocationName, estimateArrival } = this.item;
      return [{ originLocationName, estimateArrival, multiPick: this.item.subOrder.multiPick }];
    },
    multiDropItems() {
      const { destinationLocationName, estimateArrival } = this.item;
      return [{ destinationLocationName, estimateArrival, multiDrop: this.item.subOrder.multiDrop }];
    },
  },
  methods: {
    dateFormat,
    timeFormat,
  },
};
</script>
