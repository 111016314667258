<template>
  <v-dialog
    v-model="dialog"
    persistent
    scrollable
    width="800"
  >
    <v-card :loading="isLoading">
      <v-card-title class="caption">
        <h3>{{$_strings.order.CHANGE_QUANTITY_ORDER}} {{orderSelected.shipmentsTitle}}</h3>
        <v-row>
          <v-col class="d-flex pa-0 ma-0 justify-end">
            <v-toolbar-items>
              <v-btn
                icon
                color="black"
                dark
                @click="dialog = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="mt-4">
        <v-row>
          <v-col cols="12" sm="4">
            <h2 class="caption">{{$_strings.order.UNIT}}</h2>
            <div class="d-flex">
              <v-select
                v-model="form.unit"
                :items="itemsUnitPrice"
                hide-details
                dense
                outlined
              >
              </v-select>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-data-table
              hide-default-footer
              :headers="headers"
              calculate-widths
              :items="form.quantityDetail"
            >
              <template v-slot:[`item.travelDocumentNo`]={item}>
                <v-text-field
                  dense
                  hide-details
                  outlined
                  class="caption"
                  v-model="item.travelDocumentNo"
                ></v-text-field>
              </template>
              <template v-slot:[`item.travelDocumentDate`]="{item, index}">
                <v-menu
                  :ref="menuTravelDocumentDate[index]"
                  v-model="menuTravelDocumentDate[index]"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      hide-details
                      outlined
                      class="caption"
                      placeholder="Tanggal"
                      :value="item.travelDocumentDate ? dateFormat(item.travelDocumentDate) : null"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="item.travelDocumentDate"
                    no-title
                    @change="menuTravelDocumentDate[index] = false"
                    locale="id"
                  >
                  </v-date-picker>
                </v-menu>
              </template>
              <template v-slot:[`item.tonnage`]={item}>
                <v-text-field
                  outlined
                  v-mask="{'alias': 'decimal',rightAlign: false}"
                  v-model="item.tonnage"
                  dense
                  hide-details
                ></v-text-field>
              </template>
              <template v-slot:[`item.cubic`]={item}>
                <v-text-field
                  outlined
                  v-mask="{'alias': 'decimal',rightAlign: false}"
                  v-model="item.cubic"
                  dense
                  hide-details
                ></v-text-field>
              </template>
              <template v-slot:[`item.carton`]={item}>
                <v-text-field
                  outlined
                  v-mask="{'alias': 'custom-integer',rightAlign: false}"
                  v-model="item.carton"
                  dense
                  hide-details
                ></v-text-field>
              </template>
              <template v-slot:[`item.deleteRow`]=item>
                <v-icon
                  @click="deleteRow(item)"
                  color="red">
                  mdi-minus-circle
                </v-icon>
              </template>
            </v-data-table>
            <div class="mt-2">
              <v-btn
                @click="addRowTable"
                class="pa-0"
                text>
                <v-icon color="primary">mdi-plus-circle</v-icon>
                <span class="primary--text">Tambah Baris</span>
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="red"
          class="white--text"
          @click="dialog = false"
        >
          {{$_strings.common.CANCEL}}
        </v-btn>
        <v-btn
          color="primary"
          @click="save"
          :disabled="isLoading || form.quantityDetail.length === 0"
        >
          {{$_strings.common.SAVE}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import dayjs from 'dayjs';
import { dateFormat, convertTextTypeCost } from '@/helper/commonHelpers';

export default {
  props: {
    isDelivery: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    dialog(newVal) {
      if (newVal) {
        this.form.shipmentId = this.orderSelected.shipmentsId;
        this.fetchData();
        return;
      }
      this.form = {
        id: null,
        shipmentId: '',
        unit: '',
        quantityDetail: [],
      };
    },
  },
  data() {
    return {
      isLoading: false,
      isLoadingListQty: false,
      dialog: false,
      itemsUnitPrice: [],
      menuTravelDocumentDate: [],
      headers: [
        {
          text: this.$_strings.order.NO_ROAD_LETTER,
          value: 'travelDocumentNo',
          class: 'white--text primary',
          sortable: false,
          width: '200px',
        },
        {
          text: this.$_strings.common.DATE,
          value: 'travelDocumentDate',
          class: 'white--text primary',
          sortable: false,
          width: '150px',
        },
        {
          text: this.$_strings.order.TONNAGE,
          value: 'tonnage',
          class: 'white--text primary',
          sortable: false,
        },
        {
          text: this.$_strings.order.CUBIC,
          value: 'cubic',
          class: 'white--text primary',
          sortable: false,
        },
        {
          text: this.$_strings.order.BOX_CARTON,
          value: 'carton',
          class: 'white--text primary',
          sortable: false,
        },
        {
          text: '',
          value: 'deleteRow',
          class: 'white--text primary',
          sortable: false,
        },
      ],
      items: [],
      orderSelected: {},
      form: {
        id: null,
        shipmentId: '',
        unit: '',
        quantityDetail: [],
      },
    };
  },
  methods: {
    dayjs,
    dateFormat,
    convertTextTypeCost,
    fetchData() {
      this.getListQty();
      this.getUnitPrice();
    },
    async getListQty() {
      this.isLoadingListQty = true;
      try {
        const { shipmentsCargoId, shipmentsId } = this.orderSelected;
        const result = await this.$_services.order.getListQty(shipmentsCargoId, shipmentsId, this.isDelivery);
        if (result.data) {
          this.form = {
            ...this.form,
            ...result.data,
            shipmentId: shipmentsId,
          };
        }
      } finally {
        this.isLoadingListQty = false;
      }
    },
    async getUnitPrice() {
      this.isLoading = true;
      try {
        const { shipmentsCargoId } = this.orderSelected;
        const result = await this.$_services.order.getUnitPrice(shipmentsCargoId);
        if (result.data) {
          if (result.data.length) {
            this.itemsUnitPrice = result.data.map((unitPrice) => ({
              value: unitPrice.unit,
              text: this.convertTextTypeCost(unitPrice.unit),
            }));
          } else {
            this.itemsUnitPrice = [{
              value: result.data.unit,
              text: this.convertTextTypeCost(result.data.unit),
            }];
            this.form.unit = result.data.unit;
          }
        }
      } finally {
        this.isLoading = false;
      }
    },
    deleteRow(item) {
      this.form.quantityDetail.splice(item.index, 1);
    },
    addRowTable() {
      this.form.quantityDetail.push({
        id: null,
        travelDocumentNo: null,
        travelDocumentDate: null,
        tonnage: 0,
        cubic: 0,
        carton: 0,
      });
    },
    formValidate() {
      const { unit } = this.form;
      const { serviceType } = this.orderSelected;
      let valid = true;
      let isValidFormLcl = true;
      let isMinusNumber = false;
      if (serviceType === 'LCL') {
        Object.keys(this.form.quantityDetail).forEach((index) => {
          Object.keys(this.form.quantityDetail[index]).forEach((nameObj) => {
            if (
              !this.form.quantityDetail[index][nameObj]
                && (this.form.quantityDetail[index][nameObj] !== 0 && unit.toLowerCase() !== nameObj)
                && !(nameObj === 'id')
                && nameObj !== 'quantity'
            ) {
              valid = false;
            }
            if (
              (!this.form.quantityDetail[index][nameObj])
              && unit.toLowerCase() === nameObj) {
              isValidFormLcl = false;
            }
            if (
              +this.form.quantityDetail[index][nameObj] < 0
              && nameObj !== 'travelDocumentNo'
            ) {
              isMinusNumber = true;
            }
          });
        });
      } else {
        Object.keys(this.form.quantityDetail).forEach((index) => {
          Object.keys(this.form.quantityDetail[index]).forEach((nameObj) => {
            if (
              !this.form.quantityDetail[index][nameObj]
                && this.form.quantityDetail[index][nameObj] !== 0
                && !(nameObj === 'id')
                && nameObj !== 'quantity'
            ) {
              valid = false;
            }
            if (
              +this.form.quantityDetail[index][nameObj] < 0
              && nameObj !== 'travelDocumentNo'
            ) {
              isMinusNumber = true;
            }
          });
        });
      }
      if (!isValidFormLcl) {
        this.$dialog.notify.error(`${this.convertTextTypeCost(unit)} harus lebih dari 0`);
        return false;
      }
      if (isMinusNumber) {
        this.$dialog.notify.error('Value harus lebih dari 0');
        return false;
      }
      if (isValidFormLcl && !valid) {
        this.$dialog.notify.error('Mohon periksa field yang belum diisi');
        return false;
      }
      return true;
    },
    async save() {
      const { shipmentsCargoId } = this.orderSelected;
      try {
        if (!this.formValidate()) {
          return;
        }
        if (!this.form.unit) {
          return this.$dialog.notify.error('Unit belum dipilih');
        }
        // FORMAT DATE quantityDetail
        const data = {
          ...this.form,
          quantityDetail: this.form.quantityDetail.map((qty) => ({
            ...qty,
            travelDocumentDate: dayjs(qty.travelDocumentDate).format('YYYY-MM-DD'),
          })),
        };
        this.isLoading = true;
        await this.$_services.order.saveQty(shipmentsCargoId, data, this.isDelivery);
        this.$dialog.notify.success('Berhasil');
        this.dialog = false;
        this.$emit('refetchSubOrder');
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
