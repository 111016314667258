<template>
  <v-container class="pa-0 ma-0" fluid>
    <FilterOrder
      :orderBy="orderByTitle"
      v-bind="$props"
      :filters="filters"
      @fetchOrders="fetchOrders"
      @fetching="$emit('fetching', status)"
    />
    <v-sheet class="mb-0" elevation="1">
      <v-data-table
        item-key="cargoTitle"
        show-expand
        class="body"
        :headers="displayedHeaders"
        :items="items"
        :expanded.sync="expanded"
        :server-items-length="totalItems"
        :options.sync="pagination"
        :footer-props="{showCurrentPage: true, showFirstLastPage: true, 'items-per-page-options': $_item_per_page}"
        :loading="isLoading"
        :loading-text="$_strings.order.LOADING_TEXT"
        @click:row="rowClicked"
        @item-expanded="({value, item}) => value && fetchSubOrder(item)"
      >
        <template
          v-slot:expanded-item="{ headers, item }"
        >
          <expanded-item
            :headers="headers"
            :item="item"
            :status="status"
            :filters="filters"
            @fetchOrders="fetchOrders"
            @fetchSubOrder="fetchSubOrder"
            @orderExists="orderExists"
            @rowClicked="rowClicked"
          />
        </template>
        <template v-slot:[`item.pickupDate`]={item}>
          <p class="ma-0">{{dateFormat(item.pickupDate)}}</p>
          <span v-if="item.pickupDate">{{timeFormat(item.pickupDate)}}</span>
        </template>
        <template v-slot:[`item.transportModel`]={item}>
          <span>{{transportModel(item.transportModel)}}</span>
        </template>
        <template v-slot:[`item.serviceType`]={item}>
          <span>{{item.serviceType === 'FCL_BACKHAULING' ? 'FCL+BACKHAULING' : item.serviceType}}</span>
        </template>
        <template v-slot:[`item.latestStatus`]={item}>
          <a
            v-if="item.latestStatus === 'MENUNGGU_KONFIRMASI_DRIVER' || item.latestStatus === 'DRIVER_WAKTU_HABIS'"
            @click="setDialog('dialogConfirmationListDriver', item)"
          >
            {{removeUnderscoreString(item.latestStatus)}}<br/>
            {{item.isAutoAssign ? '(Auto-Assign)' : ''}}
          </a>
          <span v-else :class="statusColor(item.latestStatus)">
            {{removeUnderscoreString(item.latestStatus)}}<br/>
            {{item.isAutoAssign ? '(Auto-Assign)' : ''}}
          </span>
        </template>
        <template v-slot:[`item.actions`]={item}>
          <btn-actions
            :item="item"
            @fetchOrders="fetchOrders"
            @orderExists="orderExists"
            :status="status"
          />
        </template>
        <template v-slot:[`footer.page-text`]="props">
          <span>
            {{$_strings.order.PAGE_NAME}}
            <span v-if="items.length">
              {{props.pageStart}}-{{props.pageStop}} of {{props.itemsLength}}
            </span>
          </span>
        </template>
      </v-data-table>
    </v-sheet>
  </v-container>
</template>

<script>
import axios from 'axios';
import dayjs from 'dayjs';

import {
  skipEmptyStringObject,
  handleSortBy,
  handlerPagination,
  defaultPagination,
  shipmentColorStatus,
  perseRelativeTime,
  transportModel,
  statusOrder,
  dateFormat,
  timeFormat,
} from '@/helper/commonHelpers';
import BtnActions from './BtnActions.vue';
import ExpandedItem from './ExpandedItem.vue';
import FilterOrder from '../FilterOrder.vue';

const { CancelToken } = axios;
let source = CancelToken.source();

export default {
  name: 'order-list',
  props: {
    status: {
      type: String,
      default: '',
    },
    itemsFilter: {
      type: Object,
      default: () => {},
    },
    pageFilters: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    FilterOrder,
    BtnActions,
    ExpandedItem,
  },
  created() {
    source = CancelToken.source();
  },
  beforeDestroy() {
    const { status } = this.$route.params;
    if (status !== this.status) source.cancel('CANCELED_BY_THE_USER');
  },
  data() {
    return {
      isLoading: false,
      componentCreated: false,
      items: [],
      totalItems: 0,
      headers: [
        {
          text: '',
          value: 'data-table-expand',
          class: 'white--text primary text-capitalize',
        },
        {
          text: this.$_strings.order.GROUP_TITLE_NUMBER,
          value: 'groupTitle',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
        },
        {
          text: this.$_strings.order.ORDER_NO,
          value: 'cargoTitle',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
        },
        {
          text: this.$_strings.order.DELIVERY_DATE,
          value: 'pickupDate',
          width: '120px',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
        },
        {
          text: this.$_strings.order.ORIGIN,
          value: 'originLocationName',
          width: '200px',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
        },
        {
          text: this.$_strings.order.DESTINATION,
          value: 'destinationLocationName',
          width: '200px',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
        },
        {
          text: this.$_strings.order.TYPE_MODA,
          value: 'transportModel',
          width: '120px',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
        },
        {
          text: this.$_strings.order.DESC_TARIF,
          value: 'serviceType',
          width: '120px',
          class: 'white--text primary',
          cellClass: 'clickable',
        },
        {
          text: this.$_strings.order.SHIPPER,
          value: 'abbreviation',
          align: 'center',
          width: '120px',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
        },
        {
          text: this.$_strings.order.STATUS,
          value: 'latestStatus',
          align: 'center',
          width: '200px',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
        },
        {
          text: this.$_strings.common.ACTION,
          value: 'actions',
          width: '120px',
          class: 'white--text primary text-capitalize',
        },
      ],
      expanded: [],
      pagination: defaultPagination(),
      filters: {
        pickupDateFrom: this.$route.query.pickupDateFrom || '',
        pickupDateTo: this.$route.query.pickupDateTo || '',
        cargoTitle: this.$route.query.cargoTitle || '',
        originLocationId: this.$route.query.originLocationId || '',
        destinationLocationId: this.$route.query.destinationLocationId || '',
        licensePlateNo: this.$route.query.licensePlateNo || '',
        shipperAbbreviation: this.$route.query.shipperAbbreviation || '',
        latestStatus: this.$route.query.latestStatus || '',
        isCalculated: this.$route.query.isCalculated || '',
        isPod: this.$route.query.isPod || '',
      },
      historyPath: null,
    };
  },
  computed: {
    orderByTitle() {
      switch (this.status) {
        case 'waiting': return 'Pesanan Menunggu';
        case 'process': return 'Dalam Proses';
        default: return 'Selesai';
      }
    },
    displayedHeaders() {
      const canUpdate = this.menuFunctions.KONRIMASI_PESANAN;
      return this.headers.filter((header) => {
        if ((header.value === 'actions' && !canUpdate) || (header.value === 'actions' && this.status !== 'waiting')) return;
        return header;
      });
    },
    waitingOrder() {
      return this.$store.getters['user/waitingOrder'];
    },
  },
  watch: {
    $route: function setHistoryPath(newVal, oldVal) {
      if (oldVal.params.status === this.status) {
        this.historyPath = oldVal.fullPath;
      }
    },
    '$route.path': function setQueryString() {
      const { status } = this.$route.params;
      const { query } = this.$route;
      if (status === this.status && !Object.keys(query).length) {
        this.$router.replace(this.historyPath);
      }
    },
    pagination: {
      handler(newVal) {
        handlerPagination(this, newVal);
        this.fetchOrders();
      },
      deep: true,
    },
    waitingOrder() {
      if (this.componentCreated) this.fetchOrders();
    },
  },
  methods: {
    dayjs,
    statusOrder,
    dateFormat,
    timeFormat,
    handleSortBy,
    skipEmptyStringObject,
    transportModel,
    rowClicked(item) {
      const { pickupDateFrom, pickupDateTo } = this.filters;
      const params = {
        id: item.id,
        data: item,
      };
      this.$router.push({
        name: 'order-detail',
        params,
        query: {
          status: this.getStatusFetchData()[this.status],
          cargoTitle: item.cargoTitle,
          pickupDateFrom,
          pickupDateTo,
        },
      });
    },
    statusColor(latestStatus) {
      switch (latestStatus) {
        case 'BARANG_DITERIMA_UTUH':
        case 'BARANG_DITERIMA_TIDAK_UTUH':
        case 'DITERIMA_UTUH': return 'green--text';
        case 'DITOLAK':
        case 'KEDALUWARSA':
        case 'WAKTU_HABIS':
        case 'DIBATALKAN': return 'red--text';
        default: return 'black--text';
      }
    },
    removeUnderscoreString(string) {
      if (!string) return '-';
      const str = string.replace(/_/g, ' ')
        .toLowerCase()
        .replace(/^\w|\s\w/g, (s) => s.toUpperCase());
      return str;
    },
    showLoading(boolean) {
      if (this.items.length > 0) {
        this.isLoading = false;
        return boolean ? this.$root.$loading.show() : this.$root.$loading.hide();
      }
      this.$root.$loading.hide();
      this.isLoading = boolean;
    },
    getStatusFetchData() {
      return {
        waiting: 'WAITING',
        process: 'ON_PROCESS',
        finish: 'FINISH',
      };
    },
    async orderExists() {
      try {
        const res = await this.$_services.order.orderExists();
        this.$store.dispatch('user/setWaitingOrder', res.data);
      } catch (e) {
        console.error(e);
      }
    },
    async fetchOrders() {
      const {
        sortBy,
        sortDesc,
        page,
        itemsPerPage,
      } = this.pagination;

      const filters = this.skipEmptyStringObject({
        ...this.filters,
        page: page - 1,
        size: itemsPerPage,
        sort: this.handleSortBy({ defaultValue: 'estimateArrival,ASC', sortBy, sortDesc }),
      });

      try {
        this.showLoading(true);
        const result = await this.$_services.order.orderList(filters, this.getStatusFetchData()[this.status], source);
        this.items = result.data.contents.map((order, index) => ({
          type: 'Order',
          index,
          ...order,
        }));
        this.expanded = [];
        this.totalItems = result.data.totalData;
        if (this.status === 'waiting') this.componentCreated = true;
      } finally {
        this.showLoading(false);
      }
    },
    async fetchSubOrder(item) {
      if (item.subOrder) return;
      this.$set(item, 'subOrderLoading', true);
      try {
        const result = await this.$_services.order.getOrderById(item.id, source);
        this.$set(item, 'subOrder', result.data);
      } finally {
        this.$delete(item, 'subOrderLoading');
      }
    },
  },
};
</script>
