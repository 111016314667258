var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","scrollable":"","width":"800"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"loading":_vm.isLoading}},[_c('v-card-title',{staticClass:"caption"},[_c('h3',[_vm._v(_vm._s(_vm.$_strings.order.CHANGE_QUANTITY_ORDER)+" "+_vm._s(_vm.orderSelected.shipmentsTitle))]),_c('v-row',[_c('v-col',{staticClass:"d-flex pa-0 ma-0 justify-end"},[_c('v-toolbar-items',[_c('v-btn',{attrs:{"icon":"","color":"black","dark":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1)],1)],1),_c('v-card-text',{staticClass:"mt-4"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('h2',{staticClass:"caption"},[_vm._v(_vm._s(_vm.$_strings.order.UNIT))]),_c('div',{staticClass:"d-flex"},[_c('v-select',{attrs:{"items":_vm.itemsUnitPrice,"hide-details":"","dense":"","outlined":""},model:{value:(_vm.form.unit),callback:function ($$v) {_vm.$set(_vm.form, "unit", $$v)},expression:"form.unit"}})],1)])],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"hide-default-footer":"","headers":_vm.headers,"calculate-widths":"","items":_vm.form.quantityDetail},scopedSlots:_vm._u([{key:"item.travelDocumentNo",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{staticClass:"caption",attrs:{"dense":"","hide-details":"","outlined":""},model:{value:(item.travelDocumentNo),callback:function ($$v) {_vm.$set(item, "travelDocumentNo", $$v)},expression:"item.travelDocumentNo"}})]}},{key:"item.travelDocumentDate",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-menu',{ref:_vm.menuTravelDocumentDate[index],attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"caption",attrs:{"dense":"","hide-details":"","outlined":"","placeholder":"Tanggal","value":item.travelDocumentDate ? _vm.dateFormat(item.travelDocumentDate) : null,"prepend-inner-icon":"mdi-calendar","readonly":""}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.menuTravelDocumentDate[index]),callback:function ($$v) {_vm.$set(_vm.menuTravelDocumentDate, index, $$v)},expression:"menuTravelDocumentDate[index]"}},[_c('v-date-picker',{attrs:{"no-title":"","locale":"id"},on:{"change":function($event){_vm.menuTravelDocumentDate[index] = false}},model:{value:(item.travelDocumentDate),callback:function ($$v) {_vm.$set(item, "travelDocumentDate", $$v)},expression:"item.travelDocumentDate"}})],1)]}},{key:"item.tonnage",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:({'alias': 'decimal',rightAlign: false}),expression:"{'alias': 'decimal',rightAlign: false}"}],attrs:{"outlined":"","dense":"","hide-details":""},model:{value:(item.tonnage),callback:function ($$v) {_vm.$set(item, "tonnage", $$v)},expression:"item.tonnage"}})]}},{key:"item.cubic",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:({'alias': 'decimal',rightAlign: false}),expression:"{'alias': 'decimal',rightAlign: false}"}],attrs:{"outlined":"","dense":"","hide-details":""},model:{value:(item.cubic),callback:function ($$v) {_vm.$set(item, "cubic", $$v)},expression:"item.cubic"}})]}},{key:"item.carton",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:({'alias': 'custom-integer',rightAlign: false}),expression:"{'alias': 'custom-integer',rightAlign: false}"}],attrs:{"outlined":"","dense":"","hide-details":""},model:{value:(item.carton),callback:function ($$v) {_vm.$set(item, "carton", $$v)},expression:"item.carton"}})]}},{key:"item.deleteRow",fn:function(item){return [_c('v-icon',{attrs:{"color":"red"},on:{"click":function($event){return _vm.deleteRow(item)}}},[_vm._v(" mdi-minus-circle ")])]}}],null,true)}),_c('div',{staticClass:"mt-2"},[_c('v-btn',{staticClass:"pa-0",attrs:{"text":""},on:{"click":_vm.addRowTable}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-plus-circle")]),_c('span',{staticClass:"primary--text"},[_vm._v("Tambah Baris")])],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"white--text",attrs:{"color":"red"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" "+_vm._s(_vm.$_strings.common.CANCEL)+" ")]),_c('v-btn',{attrs:{"color":"primary","disabled":_vm.isLoading || _vm.form.quantityDetail.length === 0},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$_strings.common.SAVE)+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }