<template>
  <v-sheet elevation="1">
    <span class="body pa-3 d-block">{{orderBy}}</span>
    <v-row
      dense
      align="center"
      class="pa-5"
    >
      <v-col cols="12" sm="8">
        <v-row dense>
          <v-col cols="6" sm="auto">
            <v-menu
              ref="menuFromDate"
              v-model="menuFromDate"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  hide-details
                  outlined
                  class="caption"
                  placeholder="From Date"
                  :value="dayjs(filters.pickupDateFrom).format('DD-MM-YYYY')"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="filters.pickupDateFrom"
                no-title
                :max="filters.pickupDateTo"
                @change="menuFromDate = false"
                locale="id"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="auto" class="d-none d-sm-flex align-center">
            <span>-</span>
          </v-col>
          <v-col cols="6" sm="auto">
            <v-menu
              ref="menuToDate"
              v-model="menuToDate"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  hide-details
                  class="caption"
                  outlined
                  :value="dayjs(filters.pickupDateTo).format('DD-MM-YYYY')"
                  placeholder="To Date"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                :min="filters.pickupDateFrom"
                v-model="filters.pickupDateTo"
                no-title
                @change="menuToDate = false"
                locale="id"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="auto">
            <FilterBy
              v-bind="$props"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        sm="4"
        md="3"
        class="ml-md-auto"
      >
        <v-text-field
          v-model="search"
          dense
          hide-details
          outlined
          class="caption"
          @keyup.native.enter="setSearch"
          :placeholder="$_strings.order.SEARCHNO"
        >
          <template v-slot:prepend-inner>
            <v-icon @click="setSearch">
              mdi-magnify
            </v-icon>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import dayjs from 'dayjs';
import FilterBy from './FilterBy.vue';

export default {
  name: 'filter-order',
  props: {
    orderBy: String,
    filters: {
      type: Object,
      default: () => {},
    },
    itemsFilter: {
      type: Object,
      default: () => {},
    },
    pageFilters: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    FilterBy,
  },
  mounted() {
    const { pickupDateFrom, pickupDateTo } = this.$route.query;
    const currentDate = dayjs().add(7, 'day').format('YYYY-MM-DD');
    this.filters.pickupDateFrom = pickupDateFrom || dayjs(currentDate).subtract(7, 'day').format('YYYY-MM-DD');
    this.filters.pickupDateTo = pickupDateTo || currentDate;
  },
  data() {
    return {
      search: this.$route.query.cargoTitle || '',
      menuFromDate: false,
      pickupDateFrom: '',
      fromDateFormat: '',
      menuToDate: false,
      pickupDateTo: '',
      toDateFormat: '',
    };
  },
  watch: {
    'filters.pickupDateFrom': function pickupDateFrom(newVal, oldVal) {
      if (this.$route.query.pickupDateFrom !== newVal) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            pickupDateFrom: newVal,
            page: 1,
          },
        });
      }
      if (oldVal) {
        this.$emit('fetching');
      }
    },
    'filters.pickupDateTo': function setShipmentDateEnd(newVal, oldVal) {
      if (this.$route.query.pickupDateTo !== newVal) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            pickupDateTo: newVal,
            page: 1,
          },
        });
      }
      if (oldVal) {
        this.$emit('fetching');
      }
    },
  },
  methods: {
    dayjs,
    setSearch() {
      this.filters.search = this.search;
      if (this.search !== this.$route.query.search) {
        this.$router.replace({
          name: this.$route.name,
          query: {
            ...this.$route.query,
            cargoTitle: this.search,
            page: 1,
          },
        });
      }
      this.$emit('fetching');
    },
  },
};
</script>

<style lang="scss" scoped>
.v-text-field--outlined.v-input--dense .v-input__control .v-text-field__slot > label.v-label {
  font-size: 10px !important;
  color: red !important;
}
</style>
