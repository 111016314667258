<template>
  <td v-if="item.subOrderLoading" :colspan="headers.length">
    <v-row justify="center">
      <v-col cols="auto">
        <v-progress-circular
          class="mx-auto"
          color="primary"
          size="30"
          indeterminate
        ></v-progress-circular>
      </v-col>
    </v-row>
  </td>
  <td v-else-if="item.subOrder" class="white" :colspan="headers.length">
    <v-container fluid>
      <v-sheet outlined class="pa-2 rounded">
        <v-row v-if="item.subOrder.origin">
          <v-col>
            <h4 class="pl-2">{{$_strings.order.LABEL_ORIGIN}} : {{item.subOrder.origin}}</h4>
          </v-col>
        </v-row>
        <shipper-info
          :item="item"
          :filters="filters"
          :status="status"
        />
        <trip-info :item="item" />
        <vehicle-info
          :item="item"
          :status="status"
          @fetchOrders="$emit('fetchOrders')"
          @fetchSubOrder="(item) => $emit('fetchSubOrder', item)"
          @orderExists="$emit('orderExists')"
        />
        <qty-info
          v-if="status === 'finish' || status === 'process'"
          :item="item"
          @viewDetail="$emit('rowClicked', item)"
          @fetchSubOrder="(item) => $emit('fetchSubOrder', item)"
        />
        <v-row v-if="item.subOrder.backhauling">
          <v-col>
            <h4 class="pl-2">{{$_strings.order.LABEL_BACKHAULING}} : {{item.subOrder.backhauling}}</h4>
          </v-col>
        </v-row>
      </v-sheet>
    </v-container>
  </td>
</template>

<script>
import ShipperInfo from './ShipperInfo.vue';
import TripInfo from './TripInfo.vue';
import VehicleInfo from './VehicleInfo.vue';
import QtyInfo from './QtyInfo.vue';

export default {
  props: {
    headers: {
      type: Array,
      default: () => [],
    },
    item: {
      type: Object,
      default: () => {},
    },
    filters: {
      type: Object,
      default: () => {},
    },
    status: {
      type: String,
      default: '',
    },
  },
  components: {
    ShipperInfo,
    TripInfo,
    VehicleInfo,
    QtyInfo,
  },
};
</script>
