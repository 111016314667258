var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-row',[_c('v-col',[_c('h4',{staticClass:"pl-2"},[_vm._v(_vm._s(_vm.$_strings.order.MODA_INFO))]),_c('span',{staticClass:"pl-2 d-block"},[_vm._v(_vm._s(_vm.$_strings.order.VEHICLE_INFO))])])],1),_c('v-row',[_c('v-col',{staticClass:"pa-1"},[_c('v-data-table',{staticClass:"grey lighten-4",attrs:{"headers":_vm.displayedHeaders,"items":_vm.items,"calculate-widths":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.estimateDeparture",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.estimateDeparture && _vm.dateFormat(item.estimateDeparture) || '-'))])]}},{key:"item.estimateArrival",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.estimateArrival && _vm.dateFormat(item.estimateArrival) || '-'))])]}},{key:"item.multiModaAction",fn:function(ref){
var item = ref.item;
return [(_vm.showBtnUploadDocPod(item.latestStatus))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","small":"","icon":""},on:{"click":function($event){return _vm.navigateToPodDocument(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-file-upload ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$_strings.order.UPLOAD_DOCUMENT_POD))])]):(_vm.status !== 'finish')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","small":"","icon":""},on:{"click":_vm.setDialogMultimoda}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-circle-edit-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$_strings.order.UPDATE))])]):_vm._e()]}},{key:"item.actions",fn:function(){return [(_vm.status !== 'finish')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","small":"","icon":"","disabled":_vm.disableBtnSelectDriver},on:{"click":_vm.setDialogSelectDriverVehicle}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-circle-edit-outline ")])],1)]}}],null,false,2667311640)},[_c('span',[_vm._v(" "+_vm._s(_vm.status === 'waiting' ? _vm.$_strings.order.ASSIGN_DRIVER :_vm.$_strings.order.UPDATE)+" ")])]):_vm._e(),(_vm.status !== 'waiting')?_c('section',{staticClass:"d-inline"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.menuFunctions.VIEW_BIAYA_ONGKOS_KIRIM)?_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"primary","small":"","icon":""},on:{"click":function($event){return _vm.setDialogShippingCostOrAdditionalCost('dialogShippingCost')}}},'v-btn',attrs,false),on),[_c('v-img',{attrs:{"src":require('../../../assets/images/dolar.png'),"height":"45","width":"45"}})],1):_vm._e()]}}],null,false,2571038986)},[_c('span',[_vm._v(_vm._s(_vm.$_strings.order.LIST_SHIPPING_COST))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"primary","small":"","icon":""},on:{"click":function($event){return _vm.setDialogShippingCostOrAdditionalCost('dialogAdditionalCost')}}},'v-btn',attrs,false),on),[_c('v-img',{attrs:{"src":require('../../../assets/images/rupiah.png'),"height":"45","width":"45"}})],1)]}}],null,false,3896890484)},[_c('span',[_vm._v(_vm._s(_vm.$_strings.order.LIST_ADDITIONAL_COST))])])],1):_vm._e()]},proxy:true}],null,true)})],1)],1),_c('DialogShippingCost',{ref:"dialogShippingCost",attrs:{"page":_vm.status.toUpperCase()}}),_c('DialogListAddtioanlCost',{ref:"dialogAdditionalCost",attrs:{"page":_vm.status.toUpperCase()}}),_c('DialogUpdateMultimoda',{ref:"dialogUpdateMultimoda",on:{"refetchSubOrder":_vm.refetchSubOrder}}),_c('DialogAssignDriverVehicle',{ref:"dialogSelectAssignDriverVehicle",attrs:{"title":"Pilih Kendaraan dan Sopir","service":_vm.serviceAssignDriverVehicle,"afterAction":function () { return _vm.$emit('fetchOrders'); }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }