<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="500"
  >
    <v-card :loading="isLoading">
      <v-card-title class="caption">
        <h3>{{title}}</h3>
      </v-card-title>
      <v-card-text class="mt-4">
        <h2 class="caption">{{note = form.confirm ? $_strings.order.NOTE: $_strings.order.REASON }}</h2>
        <v-row>
          <v-col cols="12">
            <v-textarea
              :placeholder="`Isi ${note} disini`"
              no-resize
              outlined
              dense
              v-model="form.notes"
              :counter="255"
              rows="5"
              :rules="[(v) => rulesNotes(v, false, 255, note)]"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="red"
          class="white--text"
          @click="dialog = false"
        >
          {{$_strings.common.CANCEL}}
        </v-btn>
        <v-btn
          color="primary"
          @click="assigmentConfirmation"
          :disabled="!form.notes"
          :loading="isLoading"
        >
          {{$_strings.common.YES}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';
import { rulesNotes, getDateTimeTz } from '@/helper/commonHelpers';

const { CancelToken } = axios;
let source = CancelToken.source();

export default {
  data() {
    const date = new Date();
    return {
      isLoading: false,
      dialog: false,
      title: '',
      shipmentCargoId: '',
      form: {
        confirm: false,
        notes: '',
        datetimeTz: this.getDateTimeTz(),
      },
    };
  },
  watch: {
    dialog(newVal) {
      if (!newVal) {
        this.form.notes = '';
        source.cancel('CANCELED_BY_THE_USER');
        return;
      }
      source = CancelToken.source();
    },
  },
  methods: {
    rulesNotes,
    getDateTimeTz,
    async assigmentConfirmation() {
      try {
        this.isLoading = true;
        await this.$_services.order.assignmentConfirmation(this.shipmentCargoId, this.form, source);
        this.$emit('fetchOrders');
        this.$emit('checkOrderExist');
        this.$dialog.notify.success('Pesanan berhasil ditolak');
        this.dialog = false;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
