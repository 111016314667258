<template>
  <v-dialog
    v-model="dialog"
    persistent
    scrollable
    width="1200"
  >
    <v-card :loading="isLoading" class="pa-1">
      <v-card-title class="caption">
        <v-row>
          <v-col cols="10" class="d-flex justify-space-between align-center pb-0">
            <h3>{{$_strings.order.LIST_ADDITIONAL_COST}}</h3>
          </v-col>
          <v-col class="d-flex justify-end">
            <v-toolbar-items>
              <v-btn
                icon
                color="black"
                dark
                @click="dialog = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-title>
        <v-row justify="space-between">
          <v-col class="pt-0" cols="12" sm="auto">
            <p class="caption">{{itemSelected.originLocationName}} - {{itemSelected.destinationLocationName}}</p>
          </v-col>
          <v-col class="pt-0" cols="12" sm="auto">
            <p class="caption">{{itemSelected.transportTypeName}} ({{itemSelected.companyTransportNo}})</p>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="mt-4">
        <v-data-table
          :headers="headers"
          calculate-widths
          hide-default-footer
          :items="items"
        >
          <template v-slot:[`item.no`]={index}>
            <span>{{index + 1}}</span>
          </template>
          <template v-slot:[`item.amount`]={item,index}>
            <v-text-field
              dense
              outlined
              v-mask="{'alias': 'idr', rightAlign: false}"
              :value="formatCurrency(item.amount)"
              @input="($event) => currencyToNumber($event, index)"
              :disabled="item.status === 'SUDAH_DIKIRIM' || item.send || page === 'FINISH'"
              hide-details
            ></v-text-field>
          </template>
          <template v-slot:[`item.createdAt`]={item}>
            <p class="mb-0 font-weight-bold">{{item.createdBy}}</p>
            <span>{{dayjs(item.createdAt).format('DD-MM-YYYY')}}</span>
          </template>
          <template v-slot:[`item.status`]={item}>
            <p class="mb-0">{{item.status.replaceAll('_', ' ')}}</p>
          </template>
          <template v-slot:[`item.send`]={item}>
            <v-checkbox
              :disabled="item.status === 'SUDAH_DIKIRIM' || page === 'FINISH'"
              v-model="item.send"
            ></v-checkbox>
          </template>
          <template v-slot:[`item.imageUrl`]={item}>
            <v-btn
              text
              color="blue"
              :disabled="!item.imageUrl.length"
              @click="showDialogGallery(item.imageUrl)"
            >
              Lihat Gambar
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions class="mt-5">
        <v-row class="pt-2">
          <v-col offset-md="1" cols="auto">
            <h4>{{$_strings.order.TOTAL_COST}}</h4>
          </v-col>
          <v-col cols="auto">
            <p class="font-weight-bold">{{formatCurrency(totalAmount)}}</p>
          </v-col>
        </v-row>
      </v-card-actions>
      <v-card-actions v-if="page === 'PROCESS'">
        <v-row
          justify="end"
          class="ma-0"
        >
          <v-col
            cols="12"
            md="2"
            sm="3"
          >
            <v-btn
              color="red"
              class="white--text"
              small
              block
              @click="dialog = false"
            >
              {{$_strings.common.CANCEL}}
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            md="2"
            sm="3"
          >
            <v-btn
              color="primary"
              class="white--text"
              small
              block
              :disabled="isLoading || items.length === 0 || !this.getForm().length"
              @click="showDialogConfirmation"
            >
              {{$_strings.common.SAVE}}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <DialogConfirmation
      ref="dialogConfirmation"
      @submit="submit"
    />
    <DialogImageGallery
      ref="dialogImageGallery"
    />
  </v-dialog>
</template>

<script>
import axios from 'axios';
import dayjs from 'dayjs';
import DialogConfirmation from './Confirmation.vue';
import DialogImageGallery from './ImageGallery.vue';

const { CancelToken } = axios;
let source = CancelToken.source();

export default {
  props: {
    page: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      isLoading: false,
      dialog: false,
      headers: [
        {
          text: 'No',
          value: 'no',
          class: 'white--text primary',
          sortable: false,
        },
        {
          text: this.$_strings.order.COST_NAME,
          value: 'shippingCostName',
          class: 'white--text primary',
          sortable: false,
        },
        {
          text: this.$_strings.order.COST,
          value: 'amount',
          width: '200px',
          class: 'white--text primary',
          sortable: false,
        },
        {
          text: this.$_strings.order.ACTION_TIME,
          value: 'createdAt',
          class: 'white--text primary',
          sortable: false,
        },
        {
          text: this.$_strings.order.STATUS,
          value: 'status',
          class: 'white--text primary',
          sortable: false,
        },
        {
          text: this.$_strings.common.SEND,
          value: 'send',
          class: 'white--text primary',
          sortable: false,
        },
        {
          text: 'Gambar',
          value: 'imageUrl',
          class: 'white--text primary',
          sortable: false,
        },
      ],
      items: [],
      itemSelected: {},
      totalAmount: 0,
    };
  },
  components: {
    DialogConfirmation,
    DialogImageGallery,
  },
  computed: {
    isShowDialogConfirmation() {
      return this.items.filter((item) => item.send === true).length > 0;
    },
  },
  watch: {
    dialog(newVal) {
      if (!newVal) {
        this.items = [];
        this.totalAmount = 0;
        source.cancel('CANCELED_BY_THE_USER');
        return;
      }
      if (newVal) {
        source = CancelToken.source();
        this.getList();
      }
    },
  },
  methods: {
    dayjs,
    currencyToNumber(event, index) {
      const amount = event.indexOf('.') > -1 ? event.replaceAll('.', '') : event;
      if (Number(amount)) {
        this.items[index].amount = Number(amount);
        this.setTotalAmount();
        return;
      }
      this.items[index].amount = 0;
      this.setTotalAmount();
    },
    formatCurrency(amount) {
      return amount.toLocaleString('id-ID');
    },
    setTotalAmount() {
      this.totalAmount = this.items.reduce((currentValue, item) => item.amount + currentValue, 0);
    },
    async getList() {
      const { id } = this.itemSelected;
      this.isLoading = true;
      try {
        const { data } = await this.$_services.order.additionalCost(id, source);
        this.items = data.map((item) => ({
          ...item,
          send: item.status === 'SUDAH_DIKIRIM',
          newData: item.status !== 'SUDAH_DIKIRIM',
        }));
        this.setTotalAmount();
      } finally {
        this.isLoading = false;
      }
    },
    showDialogGallery(imageUrl) {
      this.$refs.dialogImageGallery.dialog = true;
      this.$refs.dialogImageGallery.imageUrl = imageUrl;
    },
    showDialogConfirmation() {
      if (this.isShowDialogConfirmation) {
        this.$refs.dialogConfirmation.title = '<div class="font-weight-bold"><p class="text-center mb-0">Anda yakin,</p><p>Memproses Biaya Tambahan ?</p></div>';
        this.$refs.dialogConfirmation.dialog = true;
      } else {
        this.submit();
      }
    },
    getForm() {
      const data = [];
      this.items.forEach((item) => {
        if (item.newData && (item.send || item.status === 'SUDAH_DIKIRIM')) {
          const objectItem = {
            id: item.id,
            status: 'SUDAH_DIKIRIM',
            amount: item.amount,
          };
          data.push(objectItem);
        }
      });
      return data;
    },
    async submit() {
      const { id } = this.itemSelected;
      if (!this.getForm().length) {
        this.dialog = false;
        return;
      }
      this.isLoading = true;
      try {
        const payload = {
          data: this.getForm(),
        };
        await this.$_services.order.updateAdditionalCost(id, payload, source);
        this.$dialog.notify.success('Berhasil');
        this.dialog = false;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
