<template>
  <v-dialog
    v-model="dialog"
    persistent
    scrollable
    width="800"
  >
    <v-card :loading="isLoading" class="pa-2">
      <v-card-title class="caption">
        <v-row>
          <v-col cols="10" class="d-flex justify-space-between align-center pb-0">
            <h3>{{$_strings.order.LIST_SHIPPING_COST}}</h3>
          </v-col>
          <v-col class="d-flex justify-end">
            <v-toolbar-items>
              <v-btn
                icon
                color="black"
                dark
                @click="dialog = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-title>
        <v-row justify="space-between">
          <v-col class="pt-0" cols="12" sm="auto">
            <p class="caption">{{itemSelected.originLocationName}} - {{itemSelected.destinationLocationName}}</p>
          </v-col>
          <v-col class="pt-0" cols="12" sm="auto">
            <p class="caption">{{itemSelected.transportTypeName}} ({{itemSelected.companyTransportNo}})</p>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="mt-4">
        <v-data-table
          :headers="headers"
          calculate-widths
          hide-default-footer
          :items="items"
        >
          <template v-slot:[`item.no`]={index}>
            <span>{{index + 1}}</span>
          </template>
          <template v-slot:[`item.amount`]={item,index}>
            <v-text-field
              dense
              outlined
              :disabled="item.status === 'SUDAH_DIKIRIM' || page === 'FINISH'"
              v-mask="{'alias': 'idr', rightAlign: false}"
              :value="formatCurrency(item.amount)"
              @input="($event) => currencyToNumber($event, index)"
              hide-details
            ></v-text-field>
          </template>
          <template v-slot:[`item.sentDate`]={item}>
            <p class="mb-0 font-weight-bold">{{item.createdBy}}</p>
            <span>{{item.sentDate && dateFormat(item.sentDate) || '-'}}</span>
          </template>
          <template v-slot:[`item.status`]={item}>
            <span>{{item.status && item.status.replaceAll('_', ' ') || '-'}}</span>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions class="mt-5">
        <v-row class="pt-2">
          <v-col offset-md="1" cols="auto">
            <h4 class="ml-4">{{$_strings.order.TOTAL_COST}}</h4>
          </v-col>
          <v-col cols="auto">
            <p class="font-weight-bold">{{formatCurrency(totalCost)}}</p>
          </v-col>
        </v-row>
      </v-card-actions>
      <v-card-actions v-if="page === 'PROCESS'">
        <v-row
          justify="end"
          class="ma-0"
        >
          <v-col
            cols="12"
            md="2"
            sm="3"
          >
            <v-btn
              color="red"
              class="white--text"
              small
              block
              @click="dialog = false"
            >
              {{$_strings.common.CANCEL}}
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            md="2"
            sm="3"
          >
            <v-btn
              color="primary"
              class="white--text"
              small
              block
              :disabled="isLoading || this.items.length === 0 || isCompleted"
              @click="submit(false)"
            >
              {{$_strings.common.SAVE}}
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            md="2"
            sm="3"
          >
            <v-btn
              color="blue"
              class="white--text"
              small
              block
              :disabled="isLoading || this.items.length === 0 || isCompleted"
              @click="showDialogConfirmation"
            >
              {{$_strings.common.SEND}}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
      <DialogConfirmation
        ref="dialogConfirmation"
        @submit="submit"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';
import dayjs from 'dayjs';
import { dateFormat } from '@/helper/commonHelpers';
import DialogConfirmation from './Confirmation.vue';

const { CancelToken } = axios;
let source = CancelToken.source();

export default {
  props: {
    page: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      isLoading: false,
      dialog: false,
      headers: [
        {
          text: 'No',
          value: 'no',
          class: 'white--text primary',
          sortable: false,
        },
        {
          text: this.$_strings.order.COST_NAME,
          value: 'shippingCostName',
          class: 'white--text primary',
          sortable: false,
        },
        {
          text: this.$_strings.order.COST,
          value: 'amount',
          width: '200px',
          class: 'white--text primary',
          sortable: false,
        },
        {
          text: this.$_strings.order.ACTION_TIME,
          value: 'sentDate',
          class: 'white--text primary',
          sortable: false,
        },
        {
          text: this.$_strings.order.STATUS,
          value: 'status',
          class: 'white--text primary',
          sortable: false,
        },
      ],
      items: [],
      itemSelected: {},
      totalCost: 0,
    };
  },
  components: {
    DialogConfirmation,
  },
  computed: {
    isCompleted() {
      let isCompleted = true;
      this.items.forEach((item) => {
        if (item.status === 'BELUM_KIRIM' || item.status === 'BELUM_DIKIRIM') isCompleted = false;
      });
      return isCompleted;
    },
  },
  watch: {
    dialog(newVal) {
      if (!newVal) {
        this.totalCost = 0;
        this.items = [];
        source.cancel('CANCELED_BY_THE_USER');
        return;
      }
      if (newVal) {
        source = CancelToken.source();
        this.getMasterShippingCost();
      }
    },
  },
  methods: {
    dayjs,
    dateFormat,
    currencyToNumber(event, index) {
      const amount = event.indexOf('.') > -1 ? event.replaceAll('.', '') : event;
      this.items[index].amount = Number(amount);
      this.setTotalAmount();
    },
    formatCurrency(amount) {
      return amount.toLocaleString('id-ID');
    },
    setTotalAmount() {
      this.totalCost = this.items.reduce((currentValue, item) => item.amount + currentValue, 0);
    },
    async getMasterShippingCost() {
      const { id } = this.itemSelected;
      this.isLoading = true;
      try {
        const { data } = await this.$_services.order.masterShippingCost(id, source);
        this.items = data;
        this.setTotalAmount();
      } finally {
        this.isLoading = false;
      }
    },
    showDialogConfirmation() {
      this.$refs.dialogConfirmation.title = '<div class="font-weight-bold"><p class="text-center mb-0">Anda yakin,</p><p>Memproses Biaya Ongkos Kirim ?</p></div>';
      this.$refs.dialogConfirmation.dialog = true;
    },
    async submit(isSent = true) {
      const form = {
        status: isSent ? 'SUDAH_DIKIRIM' : 'BELUM_DIKIRIM',
        data: this.items.map((item) => ({
          id: item.id,
          idShippingCost: item.idShippingCost,
          shippingCostName: item.shippingCostName,
          amount: item.amount,
          status: isSent ? 'SUDAH_DIKIRIM' : 'BELUM_DIKIRIM',
          notes: null,
        })),
      };
      const { id } = this.itemSelected;
      this.isLoading = true;
      try {
        await this.$_services.order.updateShippingCost(id, form, source);
        this.$dialog.notify.success('Berhasil');
        this.dialog = false;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
