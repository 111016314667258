var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","scrollable":"","width":"800"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"pa-2",attrs:{"loading":_vm.isLoading}},[_c('v-card-title',{staticClass:"caption"},[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-space-between align-center pb-0",attrs:{"cols":"10"}},[_c('h3',[_vm._v(_vm._s(_vm.$_strings.order.LIST_SHIPPING_COST))])]),_c('v-col',{staticClass:"d-flex justify-end"},[_c('v-toolbar-items',[_c('v-btn',{attrs:{"icon":"","color":"black","dark":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1)],1)],1),_c('v-card-title',[_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","sm":"auto"}},[_c('p',{staticClass:"caption"},[_vm._v(_vm._s(_vm.itemSelected.originLocationName)+" - "+_vm._s(_vm.itemSelected.destinationLocationName))])]),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","sm":"auto"}},[_c('p',{staticClass:"caption"},[_vm._v(_vm._s(_vm.itemSelected.transportTypeName)+" ("+_vm._s(_vm.itemSelected.companyTransportNo)+")")])])],1)],1),_c('v-card-text',{staticClass:"mt-4"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"calculate-widths":"","hide-default-footer":"","items":_vm.items},scopedSlots:_vm._u([{key:"item.no",fn:function(ref){
var index = ref.index;
return [_c('span',[_vm._v(_vm._s(index + 1))])]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:({'alias': 'idr', rightAlign: false}),expression:"{'alias': 'idr', rightAlign: false}"}],attrs:{"dense":"","outlined":"","disabled":item.status === 'SUDAH_DIKIRIM' || _vm.page === 'FINISH',"value":_vm.formatCurrency(item.amount),"hide-details":""},on:{"input":function ($event) { return _vm.currencyToNumber($event, index); }}})]}},{key:"item.sentDate",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"mb-0 font-weight-bold"},[_vm._v(_vm._s(item.createdBy))]),_c('span',[_vm._v(_vm._s(item.sentDate && _vm.dateFormat(item.sentDate) || '-'))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.status && item.status.replaceAll('_', ' ') || '-'))])]}}],null,true)})],1),_c('v-card-actions',{staticClass:"mt-5"},[_c('v-row',{staticClass:"pt-2"},[_c('v-col',{attrs:{"offset-md":"1","cols":"auto"}},[_c('h4',{staticClass:"ml-4"},[_vm._v(_vm._s(_vm.$_strings.order.TOTAL_COST))])]),_c('v-col',{attrs:{"cols":"auto"}},[_c('p',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.formatCurrency(_vm.totalCost)))])])],1)],1),(_vm.page === 'PROCESS')?_c('v-card-actions',[_c('v-row',{staticClass:"ma-0",attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"12","md":"2","sm":"3"}},[_c('v-btn',{staticClass:"white--text",attrs:{"color":"red","small":"","block":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" "+_vm._s(_vm.$_strings.common.CANCEL)+" ")])],1),_c('v-col',{attrs:{"cols":"12","md":"2","sm":"3"}},[_c('v-btn',{staticClass:"white--text",attrs:{"color":"primary","small":"","block":"","disabled":_vm.isLoading || this.items.length === 0 || _vm.isCompleted},on:{"click":function($event){return _vm.submit(false)}}},[_vm._v(" "+_vm._s(_vm.$_strings.common.SAVE)+" ")])],1),_c('v-col',{attrs:{"cols":"12","md":"2","sm":"3"}},[_c('v-btn',{staticClass:"white--text",attrs:{"color":"blue","small":"","block":"","disabled":_vm.isLoading || this.items.length === 0 || _vm.isCompleted},on:{"click":_vm.showDialogConfirmation}},[_vm._v(" "+_vm._s(_vm.$_strings.common.SEND)+" ")])],1)],1)],1):_vm._e(),_c('DialogConfirmation',{ref:"dialogConfirmation",on:{"submit":_vm.submit}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }