<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="700"
    scrollable
  >
    <v-card :loading="isLoading">
      <v-card-title class="caption">
        <h2>{{$_strings.order.UPDATE_MULTIMODA}}</h2>
      </v-card-title>
      <v-card-text>

        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-row class="grey mt-2 lighten-2">
            <v-col cols="12" sm="6" class="d-flex align-center">
              <span class="caption">{{$_strings.order.ORDER_NO}}</span>
              <v-text-field
                :value="shipment.cargoTitle"
                :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.order.ORDER_NO)]"
                solo
                class="caption ml-1 "
                outlined
                dense
                flat
                readonly
                hide-details
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="d-flex align-center">
              <span class="caption">{{$_strings.order.CURRENT_NOW}}</span>
              <v-text-field
                :value="statusOrder(shipment.latestStatus)"
                :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.order.CURRENT_NOW)]"
                solo
                class="caption ml-2"
                hide-details
                outlined
                dense
                flat
                readonly
                disabled
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- BODY -->
          <v-row class="mt-2">
            <v-col cols="12" sm="6" class="pb-0">{{$_strings.order.NEW_STATUS}}</v-col>
            <v-col cols="12" sm="6" class="pb-0">
              <v-select
                :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.order.NEW_STATUS)]"
                :items="itemsStatus"
                v-model="form.latestStatus"
                class="caption"
                outlined
                dense
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" class="pb-0">{{$_strings.order.NO_CONTAINER}}</v-col>
            <v-col cols="12" sm="6" class="pb-0">
              <v-text-field
                :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.order.NO_CONTAINER)]"
                class="caption"
                v-model="form.containerNo"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" class="pb-0">{{$_strings.order.NO_CRUISE_FLIGHTS}}</v-col>
            <v-col cols="12" sm="6" class="pb-0">
              <v-text-field
                :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.order.NO_CRUISE_FLIGHTS)]"
                class="caption"
                v-model="form.flightCruiseNo"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" class="pb-0">{{$_strings.order.SHIP_NAME}}</v-col>
            <v-col cols="12" sm="6" class="pb-0">
              <v-text-field
                :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.order.SHIP_NAME)]"
                class="caption"
                v-model="form.flightCruiseName"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" class="pb-0">{{$_strings.order.CRUISE_NAME_MASKAPAI}}</v-col>
            <v-col cols="12" sm="6" class="pb-0">
              <v-text-field
                class="caption"
                :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.order.CRUISE_NAME_MASKAPAI)]"
                outlined
                v-model="form.flightCruiseInfo"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <date-and-time
            class="mt-2"
            :label="$_strings.order.ESTIMATION_OF_DEPARTURE"
            :tools="tools"
            date="dateEstimationOfDepature"
            time="timeEstimationOfDepature"
            :requiredDate="checkRulesRequired('estimateDeparture')"
            :requiredTime="checkRulesRequired('estimateDeparture')"
            :minDate="form.estimateDeparture ? dayjs(form.estimateDeparture).add(1, 'days').format('YYYY-MM-DD') : null"
          ></date-and-time>
          <date-and-time
            class="mt-2"
            :label="$_strings.order.ESTIMATION_ARRIVED"
            :tools="tools"
            date="dateEstimationArrived"
            time="timeEstimationArrived"
            :minDate="form.estimateArrival ? dayjs(form.estimateArrival).add(1, 'days').format('YYYY-MM-DD') : null"
            :requiredDate="checkRulesRequired('estimateArrival')"
            :requiredTime="checkRulesRequired('estimateArrival')"
          ></date-and-time>
          <date-and-time
            class="mt-2"
            :label="$_strings.order.DEPATURE_REALIZATION"
            :tools="tools"
            date="dateDepatureRealization"
            time="timeDepatureRealization"
            :minDate="form.actualDeparture ? dayjs(form.actualDeparture).add(1, 'days').format('YYYY-MM-DD') : null"
            :requiredDate="checkRulesRequired('actualDeparture')"
            :requiredTime="checkRulesRequired('actualDeparture')"
          ></date-and-time>
          <date-and-time
            class="mt-2"
            :label="$_strings.order.REALIZATION_ARRIVES"
            :tools="tools"
            date="dateRealizationArrives"
            time="timeRealizationArrives"
            :minDate="form.actualArrival ? dayjs(form.actualArrival).add(1, 'days').format('YYYY-MM-DD') : null"
            :requiredDate="checkRulesRequired('actualArrival')"
            :requiredTime="checkRulesRequired('actualArrival')"
          ></date-and-time>
          <date-and-time
            class="mt-2"
            :label="$_strings.order.PICKUP_DATE"
            :tools="tools"
            date="dateUnloadingDate"
            time="timeUnloadingDate"
            :minDate="form.unloadingDate ? dayjs(form.unloadingDate).add(1, 'days').format('YYYY-MM-DD') : null"
            :requiredDate="checkRulesRequired('unloadingDate')"
            :requiredTime="checkRulesRequired('unloadingDate')"
          ></date-and-time>
          <v-row class="mt-2">
            <v-col cols="12" sm="6">{{$_strings.order.ADDITIONAL_INFORMATION}}</v-col>
            <v-col cols="12" sm="6">
              <v-textarea
                outlined
                dense
                v-model="form.note"
                :counter="255"
                :rules="[(v) => rulesNotes(v, false, 255, $_strings.order.ADDITIONAL_INFORMATION)]"
                name="input-7-1"
                class="caption"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="red"
          class="white--text"
          @click="dialog = false"
        >
          {{$_strings.common.CANCEL}}
        </v-btn>
        <!-- FEATURE/BASP MULTIMODA -->
        <v-btn
          v-if="shipment.latestStatus === 'BONGKAR_MUATAN' || shipment.latestStatus === 'BARANG_DITERIMA_UTUH'"
          color="red lighten-1"
          class="white--text"
          @click="navigateToFormDamageReport"
        >
          {{$_strings.order.REPORT_DIFFERENCE_2}}
        </v-btn>
        <!-- END FEATURE/BASP MULTIMODA -->
        <v-btn
          color="primary"
          @click="saveUpdate()"
          :disabled="isLoading"
        >
          {{$_strings.common.SAVE}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import dayjs from 'dayjs';
import { statusOrder, rulesNotes } from '../../../helper/commonHelpers';

const date = new Date();

export default {
  data() {
    return {
      isLoading: false,
      valid: false,
      dialog: false,
      shipment: {},
      // TOOLS FOR SPLIT DATE AND TIME
      tools: {
        dateEstimationOfDepature: '',
        timeEstimationOfDepature: '',
        dateEstimationArrived: '',
        timeEstimationArrived: '',
        dateDepatureRealization: '',
        timeDepatureRealization: '',
        dateRealizationArrives: '',
        timeRealizationArrives: '',
        dateUnloadingDate: '',
        timeUnloadingDate: '',
      },
      form: {
        id: 0,
        latestStatus: '',
        containerNo: '',
        flightCruiseNo: '',
        flightCruiseName: '',
        flightCruiseInfo: '',
        estimateDeparture: '',
        estimateArrival: '',
        actualDeparture: '',
        actualArrival: '',
        unloadingDate: '',
        note: '',
        timezone: date.toString().split('+')[1].substr(1, 1),
      },
    };
  },
  computed: {
    itemsStatus() {
      const { transportModel } = this.shipment;
      const itemsStatus = [
        {
          value: 'MENUNGGU_MUAT_BARANG',
          text: 'Menunggu Muat Barang',
        },
        {
          value: 'MUAT_BARANG',
          text: 'Muat Barang',
        },
        {
          value: 'DALAM_PENYEBRANGAN',
          text: 'Dalam Penyebrangan',
        },
        {
          value: 'SAMPAI_PELABUHAN',
          text: 'Sampai Pelabuhan',
        },
        {
          value: 'BONGKAR_MUATAN',
          text: 'Bongkar Muatan',
        },
        {
          value: 'BARANG_DITERIMA_UTUH',
          text: 'Barang Diterima Utuh',
        },
      ];
      if (transportModel === 'AIR') {
        itemsStatus.splice(2, 1, {
          value: 'DALAM_PENERBANGAN',
          text: 'Dalam Penerbangan',
        });
        itemsStatus.splice(3, 1, {
          value: 'SAMPAI_BANDARA',
          text: 'Sampai Bandara',
        });
      }
      return itemsStatus;
    },
  },
  watch: {
    shipment(newVal) {
      if (newVal) {
        this.tools = {
          dateEstimationOfDepature: '',
          timeEstimationOfDepature: '',
          dateEstimationArrived: '',
          timeEstimationArrived: '',
          dateDepatureRealization: '',
          timeDepatureRealization: '',
          dateRealizationArrives: '',
          timeRealizationArrives: '',
          dateUnloadingDate: '',
          timeUnloadingDate: '',
        };
        this.form = {
          id: 0,
          latestStatus: '',
          containerNo: '',
          flightCruiseNo: '',
          flightCruiseName: '',
          flightCruiseInfo: '',
          estimateDeparture: '',
          estimateArrival: '',
          actualDeparture: '',
          actualArrival: '',
          unloadingDate: '',
          note: '',
          timezone: date.toString().split('+')[1].substr(1, 1),
        };

        if (newVal.id) {
          this.form.id = newVal.id;
        }
      }
    },
    dialog(newVal) {
      if (newVal) {
        this.getMultimodaInfo();
      }
    },
  },
  methods: {
    rulesNotes,
    dayjs,
    statusOrder,
    checkRulesRequired(rule) {
      const { latestStatus } = this.form;
      if ((rule === 'estimateDeparture' && (latestStatus === 'MENUNGGU_MUAT_BARANG' || latestStatus === 'MUAT_BARANG')) || (rule === 'estimateArrival' && (latestStatus === 'MENUNGGU_MUAT_BARANG' || latestStatus === 'MUAT_BARANG'))) {
        return true;
      }
      if ((rule === 'actualDeparture' && latestStatus === 'DALAM_PENYEBRANGAN') || (rule === 'actualDeparture' && latestStatus === 'DALAM_PENERBANGAN')) {
        return true;
      }
      if ((rule === 'actualArrival' && latestStatus === 'SAMPAI_PELABUHAN') || (rule === 'actualArrival' && latestStatus === 'SAMPAI_BANDARA')) {
        return true;
      }
      if ((rule === 'unloadingDate' && latestStatus === 'BONGKAR_MUATAN')) {
        return true;
      }
      return false;
    },
    async getMultimodaInfo() {
      const { id } = this.shipment;
      this.isLoading = true;
      try {
        const { data } = await this.$_services.order.getMultimodaInfo(id);

        // set data
        this.form = {
          // ...data,
          actualArrival: data.actualArrival || '',
          actualDeparture: data.actualDeparture || '',
          containerNo: data.containerNo || '',
          estimateArrival: data.estimateArrival || '',
          estimateDeparture: data.estimateDeparture || '',
          flightCruiseInfo: data.flightCruiseInfo || '',
          flightCruiseName: data.flightCruiseName || '',
          flightCruiseNo: data.flightCruiseNo || '',
          id: data.id || '',
          latestStatus: data.latestStatus || '',
          note: data.null || '',
          unloadingDate: data.unloadingDate || '',
          timezone: this.form.timezone,
        };

        // set show time and date
        this.tools.dateEstimationOfDepature = data.estimateDeparture ? dayjs(data.estimateDeparture).format('YYYY-MM-DD') : '';
        this.tools.timeEstimationOfDepature = data.estimateDeparture ? dayjs(data.estimateDeparture).format('HH:mm') : '';
        this.tools.dateEstimationArrived = data.estimateArrival ? dayjs(data.estimateArrival).format('YYYY-MM-DD') : '';
        this.tools.timeEstimationArrived = data.estimateArrival ? dayjs(data.estimateArrival).format('HH:mm') : '';
        this.tools.dateDepatureRealization = data.actualDeparture ? dayjs(data.actualDeparture).format('YYYY-MM-DD') : '';
        this.tools.timeDepatureRealization = data.actualDeparture ? dayjs(data.actualDeparture).format('HH:mm') : '';
        this.tools.dateRealizationArrives = data.actualArrival ? dayjs(data.actualArrival).format('YYYY-MM-DD') : '';
        this.tools.timeRealizationArrives = data.actualArrival ? dayjs(data.actualArrival).format('HH:mm') : '';
        this.tools.dateUnloadingDate = data.unloadingDate ? dayjs(data.unloadingDate).format('YYYY-MM-DD') : '';
        this.tools.timeUnloadingDate = data.unloadingDate ? dayjs(data.unloadingDate).format('HH:mm') : '';
      } finally {
        this.isLoading = false;
      }
    },
    async saveUpdate() {
      if (!this.$refs.form.validate()) {
        this.$dialog.notify.error('Mohon Periksa Kembali');
      } else {
        const {
          dateEstimationOfDepature,
          timeEstimationOfDepature,
          dateEstimationArrived,
          timeEstimationArrived,
          dateDepatureRealization,
          timeDepatureRealization,
          dateRealizationArrives,
          timeRealizationArrives,
          dateUnloadingDate,
          timeUnloadingDate,
        } = this.tools;
        if (dateEstimationOfDepature && timeEstimationOfDepature) {
          this.form.estimateDeparture = dayjs(`${dateEstimationOfDepature} ${timeEstimationOfDepature}`).format();
        }
        if (dateEstimationArrived && timeEstimationArrived) {
          this.form.estimateArrival = dayjs(`${dateEstimationArrived} ${timeEstimationArrived}`).format();
        }
        if (dateDepatureRealization && timeDepatureRealization) {
          this.form.actualDeparture = dayjs(`${dateDepatureRealization} ${timeDepatureRealization}`).format();
        }
        if (dateRealizationArrives && timeRealizationArrives) {
          this.form.actualArrival = dayjs(`${dateRealizationArrives} ${timeRealizationArrives}`).format();
        }
        if (dateUnloadingDate && timeUnloadingDate) {
          this.form.unloadingDate = dayjs(`${dateUnloadingDate} ${timeUnloadingDate}`).format();
        }
        const {
          id,
          latestStatus,
          containerNo,
          flightCruiseNo,
          flightCruiseName,
          flightCruiseInfo,
          estimateDeparture,
          estimateArrival,
          actualDeparture,
          actualArrival,
          unloadingDate,
          note,
          timezone,
        } = this.form;
        const data = {
          id,
          latestStatus,
          containerNo,
          flightCruiseNo,
          flightCruiseName,
          flightCruiseInfo,
          estimateDeparture,
          estimateArrival,
          actualDeparture,
          actualArrival,
          unloadingDate,
          note,
          timezone,
        };
        try {
          this.isLoading = true;
          await this.$_services.order.assignMultimoda(data);
          // refetch sub order after assign
          this.$emit('refetchSubOrder');
          if (latestStatus === 'BARANG_DITERIMA_UTUH') {
            this.$dialog.notify.success('Update Status Berhasil, Silahkan Upload Dokumen POD');
          } else {
            this.$dialog.notify.success('Update Status Berhasil');
          }
        } finally {
          this.isLoading = false;
        }
      }
    },
    navigateToFormDamageReport() {
      this.$router.push({
        name: 'form-basp',
        params: {
          shipment: this.shipment,
        },
      });
    },
    setTime(timeBy, time) {
      this[timeBy] = time;
    },
  },
};
</script>
