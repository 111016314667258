<template>
  <v-sheet v-if="tab !== 2" elevation="0">
    <v-row class="pl-4 caption align-center">
      <v-col cols="auto"><span>Legend:</span></v-col>
      <v-col cols="auto">
        <v-icon color="primary">
          mdi-circle-edit-outline
        </v-icon>
        <span class="ml-2">{{$_strings.order.UPDATE}}</span>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>

export default {
  name: 'footer-order',
  props: {
    tab: {
      type: Number,
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
